import React from "react";
import { Col, Row } from "react-bootstrap";
import {
SiC,
SiCplusplus, 
SiCsharp, 
SiDart, 
SiKotlin, 
SiR, 
} from "react-icons/si";
import {
DiLinux,
  DiCss3,
  DiHtml5,
DiJavascript,
  DiJavascript1,
  DiJava,
  DiPhp,
  DiPython,
} from "react-icons/di";

function Techstack() {
  return (
    <Row style={{ justifyContent: "center", paddingBottom: "50px" }}>
      <Col xs={4} md={2} className="tech-icons">
        <DiLinux />
        <p>Bash</p>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiC />
        <p>C</p>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiCplusplus />
        <p>C++</p>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <DiCss3 />
        <p>CSS</p>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiCsharp />
        <p>C sharp</p>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
      <Col xs={4} md={2} className="tech-icons">
        <SiDart />
        <p>Dart</p>
      </Col>
        <DiHtml5 />
        <p>HTML</p>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <DiJava />
        <p>Java</p>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <DiJavascript />
        <p>JavaScript</p>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiKotlin />
        <p>Kotlin</p>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <DiPhp />
        <p>PHP</p>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <DiPython />
        <p>Python</p>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <DiJavascript1 />
        <p>TypeScript</p>
      </Col>
    </Row>
  );
}

export default Techstack;
