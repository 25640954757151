import React from "react";
import { Col, Row } from "react-bootstrap";
import {
  FaDatabase,
  FaLinux,
  FaWindows,
  FaCloud,
} from "react-icons/fa";
import {
  SiMysql,
  SiMongodb,
  SiPostgresql,
  SiKalilinux,
  SiUbuntu,
SiDebian, 
  SiAmazonaws,
  SiWindows,
  SiRedhat,
  SiCentos,
  SiGit,
  SiEclipseide,
  SiAndroidstudio,
  SiIntellijidea,
  SiPycharm,
  SiDocker,
  SiKubernetes,
} from "react-icons/si";
import {
DiVisualstudio, 
} from "react-icons/di";

function Toolstack() {
  return (
    <Row style={{ justifyContent: "center", paddingBottom: "50px" }}>
      {/* Database Management Systems */}
      <Col xs={4} md={2} className="tech-icons">
        <FaDatabase />
        <p>Database Management</p>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiMysql />
        <p>MySQL</p>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiMongodb />
        <p>MongoDB</p>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiPostgresql />
        <p>PostgreSQL</p>
      </Col>
      {/* Operating Systems */}
      <Col xs={4} md={2} className="tech-icons">
        <FaLinux />
        <p>Operating Systems</p>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiKalilinux />
        <p>Kali Linux</p>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiUbuntu />
        <p>Ubuntu</p>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiDebian />
        <p>Parrot OS</p>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiAmazonaws />
        <p>Amazon OS</p>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiWindows />
        <p>Windows</p>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiWindows />
        <p>Windows Server</p>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiRedhat />
        <p>Red Hat</p>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiCentos />
        <p>CentOS</p>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiDebian />
        <p>Cloud Linux</p>
      </Col>
      {/* Tools */}
      <Col xs={4} md={2} className="tech-icons">
        <FaCloud />
        <p>Tools</p>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiGit />
        <p>Git</p>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <DiVisualstudio />
        <p>Visual Studio</p>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiEclipseide />
        <p>Eclipse</p>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiAndroidstudio />
        <p>Android Studio</p>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiIntellijidea />
        <p>IntelliJ IDEA</p>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiPycharm />
        <p>PyCharm</p>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiDocker />
        <p>Docker</p>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiKubernetes />
        <p>Kubernetes</p>
      </Col>
    </Row>
  );
}

export default Toolstack;
