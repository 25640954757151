import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "./ProjectCards";
import Particle from "../Particle";

function Projects() {
  return (
    <Container fluid className="project-section">
      <Particle />
      <Container>
        <h1 className="project-heading">
          My Recent <strong className="purple">Works </strong>
        </h1>
        <p style={{ color: "white" }}>
          Here are a few projects I've worked on recently.
        </p>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          <Col md={4} className="project-card">
            <ProjectCard
              isBlog={false}
              title="Scientific Computing with Python"
              description="A collection of projects related to scientific computing using Python. These projects involve topics such as numerical analysis, data visualization, and mathematical modeling."
              ghLink="https://github.com/divineDev-dotcom/ScientificComputing-with-Python"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              isBlog={false}
              title="JavaScript Algorithms and Data Structures"
              description="A repository containing JavaScript projects focused on algorithms and data structures. These projects provide implementations of various algorithms and data structures commonly used in computer science."
              ghLink="https://github.com/divineDev-dotcom/JavaScript-Algorithms-and-DataStructures"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              isBlog={false}
              title="Information Security and Quality Assurance"
              description="A collection of projects related to information security and quality assurance. These projects involve tasks such as vulnerability scanning, penetration testing, and test automation."
              ghLink="https://github.com/divineDev-dotcom/Information-Security-And-Quality-Assurance"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              isBlog={false}
              title="Back-End Development Projects"
              description="A collection of projects focused on back-end development and API creation. These projects involve the use of frameworks such as Express.js and Node.js to build robust and scalable server-side applications."
              ghLink="https://github.com/divineDev-dotcom/BackEnd-Development-projects"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              isBlog={false}
              title="Data Analysis with Python"
              description="A collection of projects focused on data analysis using Python. These projects involve tasks such as data cleaning, exploratory data analysis, and statistical modeling."
              ghLink="https://github.com/divineDev-dotcom/DataAnalysis-with-Python"
            />
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default Projects;
